import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Tooltip,
    IconButton,
    Box,
    CircularProgress,
    Select,
    MenuItem,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    TextField,
    Typography
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';
import { listarImpresoras, listarUsuarios, listarEmpresasDisponibles, modificarPuesto, agregarPuesto, eliminarPuesto } from "../../../Globales/MetodosAPIs";

const TablaPuestos = ({ data, accessToken, refresh }) => {
    const [editIdx, setEditIdx] = useState(-1);
    const [editPuesto, setEditPuesto] = useState({});
    const [newPuesto, setNewPuesto] = useState({
        username: '',
        descripcion: '',
        empresa: '',
        impresora: ''
    });
    const [open, setOpen] = useState(false);
    const [usuarios, setUsuarios] = useState([]);
    const [impresoras, setImpresoras] = useState([]);
    const [empresas, setEmpresas] = useState([]);

    useEffect(() => {
        if (editIdx !== -1) {
            fetchUsuarios();
            fetchImpresoras();
        }
    }, [editIdx]);

    useEffect(() => {
        fetchUsuarios();
        fetchImpresoras();
        fetchEmpresas();
    }, []);

    const fetchUsuarios = async () => {
        const response = await listarUsuarios(accessToken);
        setUsuarios(response.data);
    };

    const fetchImpresoras = async () => {
        const response = await listarImpresoras(accessToken);
        setImpresoras(response.data);
    };

    const fetchEmpresas = async () => {
        const response = await listarEmpresasDisponibles(accessToken);
        setEmpresas(response.data);
    };

    const handleEdit = (puesto) => {
        setEditPuesto({
            ...puesto,
            impresora: puesto.impresora?.id || '',
            usuario_del_puesto: puesto.usuario_del_puesto?.id || ''
        });
        setEditIdx(puesto.id);
    };

    const handleSave = async () => {
        const response = await modificarPuesto(accessToken, {
            id: editPuesto.id,
            impresora: editPuesto.impresora,
            usuario_del_puesto: editPuesto.usuario_del_puesto
        });
        if (response.status) {
            console.log(response.mensaje);
            setEditIdx(-1);
            refresh();  // Refrescar la tabla después de guardar cambios
        } else {
            console.error(response.mensaje);
        }
    };

    const handleAdd = async () => {
        const response = await agregarPuesto(accessToken, newPuesto);
        if (response.status) {
            console.log(response.mensaje);
            setOpen(false);
            refresh();  // Refrescar la tabla después de agregar un puesto
        } else {
            console.error(response.mensaje);
        }
    };

    const handleDelete = async (puestoId) => {
        const result = await Swal.fire({
            title: '¿Estás seguro?',
            text: "Si eliminas este puesto, se perderán todos los datos asociados y no se puede deshacer.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar'
        });

        if (result.isConfirmed) {
            const response = await eliminarPuesto(accessToken, puestoId);
            if (response.status) {
                Swal.fire(
                    'Eliminado',
                    'El puesto ha sido eliminado.',
                    'success'
                );
                refresh(); // Refrescar la tabla después de eliminar el puesto
            } else {
                Swal.fire(
                    'Error',
                    response.mensaje,
                    'error'
                );
            }
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditPuesto({ ...editPuesto, [name]: value });
    };

    const handleNewPuestoChange = (e) => {
        const { name, value } = e.target;
        setNewPuesto({ ...newPuesto, [name]: value });
    };

    return (
        <TableContainer component={Paper}>
            <Box display="flex" justifyContent="flex-end" m={2}>
                <Tooltip title="Agregar Puesto">
                    <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => setOpen(true)}>
                        Agregar Puesto
                    </Button>
                </Tooltip>
            </Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Descripción</TableCell>
                        <TableCell>Empresa</TableCell>
                        <TableCell>Impresora</TableCell>
                        <TableCell>Usuario del Puesto</TableCell>
                        <TableCell>Acciones</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.length === 0 ? (
                        <TableRow>
                            <TableCell colSpan={5} align="center">
                                <CircularProgress />
                            </TableCell>
                        </TableRow>
                    ) : (
                        data.map((puesto) => (
                            <TableRow key={puesto.id}>
                                <TableCell>{puesto.descripcion}</TableCell>
                                <TableCell>{puesto.empresa?.descripcion || 'N/A'}</TableCell>
                                <TableCell>
                                    {editIdx === puesto.id ? (
                                        <Select
                                            value={editPuesto.impresora || ''}
                                            onChange={(e) => handleChange({ target: { name: 'impresora', value: e.target.value } })}
                                            fullWidth
                                        >
                                            <MenuItem value="">Sin Impresora</MenuItem>
                                            {impresoras.map((impresora) => (
                                                <MenuItem key={impresora.id} value={impresora.id}>
                                                    {impresora.descripcion}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    ) : (
                                        puesto.impresora?.descripcion || 'Sin Impresora'
                                    )}
                                </TableCell>
                                <TableCell>
                                    {editIdx === puesto.id ? (
                                        <Select
                                            value={editPuesto.usuario_del_puesto || ''}
                                            onChange={(e) => handleChange({ target: { name: 'usuario_del_puesto', value: e.target.value } })}
                                            fullWidth
                                        >
                                            <MenuItem value="">Sin Embalador</MenuItem>
                                            {usuarios.map((usuario) => (
                                                <MenuItem key={usuario.id} value={usuario.id}>
                                                    {usuario.username}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    ) : (
                                        puesto.usuario_del_puesto?.username || 'Sin Embalador'
                                    )}
                                </TableCell>
                                <TableCell>
                                    {editIdx === puesto.id ? (
                                        <>
                                            <Tooltip title="Guardar Cambios">
                                                <IconButton onClick={handleSave} color="primary">
                                                    <SaveIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Cancelar">
                                                <IconButton onClick={() => setEditIdx(-1)} color="secondary">
                                                    <ClearIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </>
                                    ) : (
                                        <>
                                            <Tooltip title="Editar Puesto">
                                                <IconButton onClick={() => handleEdit(puesto)} color="secondary">
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Eliminar Puesto">
                                                <IconButton onClick={() => handleDelete(puesto.id)} color="error">
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))
                    )}
                </TableBody>
            </Table>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Agregar Puesto</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Complete los siguientes campos para agregar un nuevo puesto.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Usuario"
                        type="text"
                        fullWidth
                        name="username"
                        value={newPuesto.username}
                        onChange={handleNewPuestoChange}
                    />
                    <TextField
                        margin="dense"
                        label="Descripción"
                        type="text"
                        fullWidth
                        name="descripcion"
                        value={newPuesto.descripcion}
                        onChange={handleNewPuestoChange}
                    />
                    <Typography variant="subtitle1" gutterBottom>
                        Empresa
                    </Typography>
                    <Select
                        fullWidth
                        name="empresa"
                        value={newPuesto.empresa}
                        onChange={handleNewPuestoChange}
                    >
                        {empresas.map((empresa) => (
                          <MenuItem key={empresa.cuit} value={empresa.cuit}>
                            <Typography>{empresa.razon_social}</Typography>
                          </MenuItem>
                        ))}
                    </Select>
                    <Typography variant="subtitle1" gutterBottom>
                        Impresora
                    </Typography>
                    <Select
                        fullWidth
                        name="impresora"
                        value={newPuesto.impresora}
                        onChange={handleNewPuestoChange}
                    >
                        <MenuItem value="">Sin Impresora</MenuItem>
                        {impresoras.map((impresora) => (
                            <MenuItem key={impresora.id} value={impresora.id}>
                                <Typography>{impresora.descripcion}</Typography>
                            </MenuItem>
                        ))}
                    </Select>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleAdd} color="primary">
                        Agregar
                    </Button>
                </DialogActions>
            </Dialog>
        </TableContainer>
    );
};

export default TablaPuestos;
