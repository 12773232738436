import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    Typography,
    IconButton,
    Collapse,
    Tooltip,
    ButtonGroup,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Select,
    MenuItem,
    CircularProgress, Snackbar, Alert, Pagination
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ClearIcon from '@mui/icons-material/Clear';
import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import PrintIcon from '@mui/icons-material/Print';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import Swal from 'sweetalert2';
import {
    agregarProductoVenta,
    cargarProductosPorMarca,
    obtener_marcas_productos,
    obtener_productos_de_empresa,
    quitarProductoVenta,
    quitar_restriccion_ean
} from "../../../Globales/MetodosAPIs";

import { useAuth } from "../../../Providers/AuthProvider";
import TablaProductos from "./TablaProductos";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import SellIcon from '@mui/icons-material/Sell';
import DeleteIcon from "@mui/icons-material/Delete";

export const CarritosPaginationTable = ({ carritos, setRefresh, filasSeleccionadas, setFilasSeleccionadas, currentPage, totalPages, setCurrentPage }) => {
    const [allOpen, setAllOpen] = useState(false);

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = carritos.map((n) => n.id);
            setFilasSeleccionadas(newSelecteds);
            return;
        }
        setFilasSeleccionadas([]);
    };

    const handleToggleAll = () => {
        setAllOpen(!allOpen);
    };

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
        setRefresh(Math.random()); // Trigger refresh or data fetch
    };


    return (
        <Box>
            <TableContainer component={Paper} style={{ maxHeight: 600, overflow: 'auto' }}>
                <Table aria-label="tabla de carritos" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox" style={{ borderBottom: "3px solid rgba(80,80,80,0.5)" }}>
                                <Checkbox
                                    indeterminate={filasSeleccionadas.length > 0 && filasSeleccionadas.length < carritos.length}
                                    checked={carritos.length > 0 && filasSeleccionadas.length === carritos.length}
                                    onChange={handleSelectAllClick}
                                    inputProps={{ 'aria-label': 'select all carritos' }}
                                />
                            </TableCell>
                            <TableCell style={{ borderBottom: "3px solid rgba(80,80,80,0.5)" }}>
                                <IconButton aria-label="expand all rows" size="small" onClick={handleToggleAll}>
                                    {allOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </IconButton>
                            </TableCell>
                            <TableCell style={{ borderBottom: "3px solid rgba(80,80,80,0.5)" }}>Código Carrito</TableCell>
                            <TableCell style={{ borderBottom: "3px solid rgba(80,80,80,0.5)" }}>Estado</TableCell>
                            <TableCell style={{ borderBottom: "3px solid rgba(80,80,80,0.5)" }}>Tipo de Envío</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {carritos.map((carrito) => (
                            <CarritoRow
                                key={carrito.id}
                                carrito={carrito}
                                setRefresh={setRefresh}
                                isSelected={filasSeleccionadas.indexOf(carrito.id) !== -1}
                                setFilasSeleccionadas={setFilasSeleccionadas}
                                filasSeleccionadas={filasSeleccionadas}
                                allOpen={allOpen}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box display="flex" justifyContent="center" mt={2}>
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handleChangePage}
                    color="primary"
                />
            </Box>
        </Box>
    );
};

const CarritosTable = ({ carritos, setRefresh, filasSeleccionadas, setFilasSeleccionadas }) => {
    const [allOpen, setAllOpen] = useState(false);

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = carritos.map((n) => n.id);
            setFilasSeleccionadas(newSelecteds);
            return;
        }
        setFilasSeleccionadas([]);
    };

    const handleToggleAll = () => {
        setAllOpen(!allOpen);
    };

    return (
        <TableContainer component={Paper} style={{ maxHeight: 600, overflow: 'auto' }}>
            <Table aria-label="tabla de carritos" stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell padding="checkbox" style={{ borderBottom: "3px solid rgba(80,80,80,0.5)" }}>
                            <Checkbox
                                indeterminate={filasSeleccionadas.length > 0 && filasSeleccionadas.length < carritos.length}
                                checked={carritos.length > 0 && filasSeleccionadas.length === carritos.length}
                                onChange={handleSelectAllClick}
                                inputProps={{ 'aria-label': 'select all carritos' }}
                            />
                        </TableCell>
                        <TableCell style={{ borderBottom: "3px solid rgba(80,80,80,0.5)" }}>
                            <IconButton aria-label="expand all rows" size="small" onClick={handleToggleAll}>
                                {allOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        </TableCell>
                        <TableCell style={{ borderBottom: "3px solid rgba(80,80,80,0.5)" }}>Código Carrito</TableCell>
                        <TableCell style={{ borderBottom: "3px solid rgba(80,80,80,0.5)" }}>Estado</TableCell>
                        <TableCell style={{ borderBottom: "3px solid rgba(80,80,80,0.5)" }}>Tipo de Envío</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {carritos.map((carrito) => (
                        <CarritoRow
                            key={carrito.id}
                            carrito={carrito}
                            setRefresh={setRefresh}
                            isSelected={filasSeleccionadas.indexOf(carrito.id) !== -1}
                            setFilasSeleccionadas={setFilasSeleccionadas}
                            filasSeleccionadas={filasSeleccionadas}
                            allOpen={allOpen}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const CarritoRow = ({ carrito, setRefresh, isSelected, setFilasSeleccionadas, filasSeleccionadas, allOpen }) => {
    const { authTokens } = useAuth();
    const [open, setOpen] = useState(allOpen);

    useEffect(() => {
        setOpen(allOpen || carrito.estado === 'en_preparacion');
    }, [allOpen, carrito.estado]);

    const hasVirtualCombo = carrito.ventas.some(venta =>
        venta.detalles_venta.some(detalle => detalle.producto.es_combo_virtual)
    );

    const labelId = `enhanced-table-checkbox-${carrito.id}`;

    const handleClick = (event, id) => {
        const selectedIndex = filasSeleccionadas.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(filasSeleccionadas, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(filasSeleccionadas.slice(1));
        } else if (selectedIndex === filasSeleccionadas.length - 1) {
            newSelected = newSelected.concat(filasSeleccionadas.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                filasSeleccionadas.slice(0, selectedIndex),
                filasSeleccionadas.slice(selectedIndex + 1),
            );
        }

        setFilasSeleccionadas(newSelected);
    };

    const getRowStyle = () => {
        if (hasVirtualCombo) {
            return { backgroundColor: 'rgba(255, 150, 136, 0.3)' };
        }
        switch (carrito.estado) {
            case 'en_preparacion':
                return { backgroundColor: 'rgba(245, 250, 193, 0.3)' }; // #f5fac1 with transparency
            default:
                return {};
        }
    };

    const handleQuitarRestriccionEAN = async () => {
        const result = await quitar_restriccion_ean(authTokens.accessToken, carrito.id);
        if (result.status) {
            Swal.fire({
                icon: 'success',
                title: 'Restricción EAN quitada',
                text: `Se ha quitado la restricción EAN para el carrito ${carrito.id}.`,
            });
            setRefresh(Math.random()); // Refresca la tabla
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: result.mensaje,
            });
        }
    };

    const formatFechaCreacion = (fecha) => {
        const date = new Date(fecha);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript son base 0
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${day}-${month}-${year} -- ${hours}:${minutes}`;
    };


    return (
        <>
            <TableRow
                sx={{
                    '& > *': { borderBottom: 'unset' },
                    ...getRowStyle()
                }}
                aria-checked={isSelected}
                tabIndex={-1}
            >
                <TableCell padding="checkbox">
                    <Checkbox
                        checked={isSelected}
                        onChange={(event) => handleClick(event, carrito.id)}
                        inputProps={{ 'aria-labelledby': labelId }}
                    />
                </TableCell>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {carrito.id}
                </TableCell>
                <TableCell>{carrito.estado_display}</TableCell>
                <TableCell>{carrito.detalles_envio.tipo_de_envio_display || "Sin especificar"}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1, borderRadius: 2 }}>
                            <Box sx={{ marginBottom: 2, padding: 2, border: '1px solid #4caf50', borderRadius: 2 }}>
                                <Typography variant="h6" gutterBottom component="div">
                                    Detalles de Envío
                                </Typography>
                                <Typography variant="body2">
                                    <strong>Tipo de Envío:</strong> {carrito.detalles_envio.tipo_de_envio_display}
                                </Typography>
                                <Typography variant="body2">
                                    <strong>Fecha Creacion:</strong> {formatFechaCreacion(carrito.fecha_creacion)}
                                </Typography>

                                <Typography variant="body2">
                                    <strong>Fecha Límite:</strong> {carrito.detalles_envio.fecha_limite_retiro || 'No especificado'}
                                </Typography>
                            </Box>

                            {carrito.estado === 'en_observacion' && carrito.detalle_observacion && (
                                <Box sx={{ marginBottom: 2, padding: 2, border: '1px solid #ff9800', borderRadius: 2, display: 'flex', justifyContent: 'space-between' }}>
                                    <Box>
                                        <Typography variant="h6" gutterBottom component="div">
                                            Detalles de la Observación
                                        </Typography>
                                        <Typography variant="body2">
                                            <strong>Motivo:</strong> {carrito.detalle_observacion.motivo_display}
                                        </Typography>
                                        <Typography variant="body2">
                                            <strong>Observación:</strong> {carrito.detalle_observacion.observacion}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ textAlign: 'right' }}>
                                        <Typography variant="h6" gutterBottom component="div">
                                            Comando
                                        </Typography>
                                        <Tooltip title="Quitar Restricción EAN">
                                            <Button color="success" onClick={handleQuitarRestriccionEAN} variant="contained">
                                                <QrCodeScannerIcon />
                                            </Button>
                                        </Tooltip>
                                    </Box>
                                </Box>
                            )}
                            {carrito.asignado_a && (
                                <Box sx={{ marginBottom: 2, padding: 2, border: '1px solid #90caf9', borderRadius: 2 }}>
                                    <Typography variant="h6" gutterBottom component="div">
                                        Usuario Asignado al Carrito
                                    </Typography>
                                    <Typography variant="body2">
                                        <strong>Username:</strong> {carrito.asignado_a.username}
                                    </Typography>
                                </Box>
                            )}
                            <VentasTable ventas={carrito.ventas} setRefresh={setRefresh} allOpen={allOpen} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

const VentasTable = ({ ventas, setRefresh, allOpen }) => {
    return (
        <Table size="small" aria-label="ventas">
            <TableHead>
                <TableRow>
                    <TableCell />
                    <TableCell>Código Venta</TableCell>
                    <TableCell>Requiere Verificación</TableCell>
                    <TableCell>Publicacion MELI</TableCell>
                    <TableCell>Acciones</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {ventas.map((venta) => (
                    <VentaRow key={venta.id} venta={venta} setRefresh={setRefresh} allOpen={allOpen} />
                ))}
            </TableBody>
        </Table>
    );
};

const VentaRow = ({ venta, setRefresh, allOpen }) => {
    const url_a_formatear = "https://www.mercadolibre.com.ar/ventas/<id_venta>/detalle?callbackUrl=https%3A%2F%2Fwww.mercadolibre.com.ar%2Fventas%2Fomni%2Flistado%3Fplatform.id%3DML%26channel%3Dmarketplace%26filters%3D%26sort%3DDATE_CLOSED_DESC%26page%3D1%26search%3D%26startPeriod%3DWITH_DATE_CLOSED_6M_OLD%26toCurrent%3D%26fromCurrent%3D";
    const [open, setOpen] = useState(allOpen);
    const [dialogOpen, setDialogOpen] = useState(false);
    const { setView, authTokens } = useAuth();
    const [cantidad, setCantidad] = useState(1);
    const hasVirtualCombo = venta.detalles_venta.some(detalle => detalle.producto.es_combo_virtual);
    const rowStyle = {
        backgroundColor: hasVirtualCombo ? 'rgba(255, 150, 136, 0.4)' : 'inherit',
        '& > *': { borderBottom: 'unset' }
    };

    const [marcas, setMarcas] = useState([]);
    const [marcaSeleccionada, setMarcaSeleccionada] = useState(null);
    const [loading, setLoading] = useState(false);
    const [productosDisponibles, setProductosDisponibles] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState("success");  // Puede ser "success", "error", etc.

    const AbrirPestana = () => {
        const urlFormateada = url_a_formatear.replace("<id_venta>", venta.id);
        window.open(urlFormateada, '_blank'); // Abre la URL en una nueva pestaña
    };

    useEffect(() => {
        setOpen(allOpen);
    }, [allOpen]);

    useEffect(() => {
        if (dialogOpen) {
            setLoading(true);
            obtener_marcas_productos(authTokens.accessToken).then(data => {
                setMarcas(data.data);
                setLoading(false);
            });
        }
    }, [dialogOpen, authTokens]);

    const handleOpenDialog = () => {
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setVista('seleccionarMarca');
        setSelectedRow(null);
        setMarcaSeleccionada(null);
    };

    const handleMarcaChange = (event) => {
        setMarcaSeleccionada(event.target.value);
    };

    const handleContinue = async () => {
        setLoading(true);  // Muestra un indicador de carga
        // Suponemos que `marcaSeleccionada` puede ser `null` o un ID de marca
        const productos = await cargarProductosPorMarca(marcaSeleccionada, authTokens.accessToken);
        setProductosDisponibles(productos || []);  // Asegura que el estado no se establezca a null
        setLoading(false);

    };
    const [vista, setVista] = useState('seleccionarMarca');  // 'seleccionarMarca' o 'mostrarTabla'

    const AgregarProductoVenta = async () => {
        setLoading(true);
        const payload = { venta_id: venta.id, producto_id: selectedRow.id, cantidad: cantidad };

        try {
            const result = await agregarProductoVenta(authTokens.accessToken, payload); // Asumiendo que tienes una API configurada
            if (result.status) {
                setAlertMessage("Producto agregado exitosamente");
                setAlertSeverity("success");
                setAlertOpen(true);
                setRefresh(Math.random());
                setVista('seleccionarMarca');
                setTimeout(() => setAlertOpen(false), 3000);
            } else {
                setAlertMessage(`Error al agregar producto: ${result.data.message}`);
                setAlertSeverity("error");
                setAlertOpen(true);
                setTimeout(() => setAlertOpen(false), 3000);
            }
        } catch (error) {
            setAlertMessage("Error al conectar con el servidor");
            setAlertSeverity("error");
            setAlertOpen(true);
            setTimeout(() => setAlertOpen(false), 3000);
        }
        setLoading(false);
    };

    const quitarProducto = async (productoId, ventaId) => {
        const payload = { venta_id: ventaId, producto_id: productoId };
        const result = await quitarProductoVenta(authTokens.accessToken, payload);
        if (result.status) {
            setRefresh(Math.random());
            setAlertMessage("Producto quitado exitosamente");
            setAlertSeverity("success");
            setAlertOpen(true);
            setTimeout(() => setAlertOpen(false), 3000);
            // Actualizar la UI o hacer una re-carga de datos si es necesario
        } else {
            setAlertMessage(`Error al quitar producto: ${result.data.message}`);
            setAlertSeverity("error");
            setAlertOpen(true);
            setTimeout(() => setAlertOpen(false), 3000);
        }
    };

    return (
        <>
            <Snackbar open={alertOpen} autoHideDuration={6000} onClose={() => setAlertOpen(false)}>
                <Alert onClose={() => setAlertOpen(false)} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMessage}
                </Alert>
            </Snackbar>

            <TableRow sx={rowStyle}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>{venta.id}</TableCell>
                <TableCell>{hasVirtualCombo ? 'SÍ' : 'NO'}</TableCell>
                <TableCell>
                    <Button onClick={AbrirPestana} startIcon={<SellIcon />} variant="contained" size="small">
                        Ir a Venta
                    </Button>
                </TableCell>
                <TableCell>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        size="small"
                        onClick={handleOpenDialog}
                    >
                        Agregar Producto
                    </Button>
                </TableCell>
            </TableRow>
            <Dialog
                open={dialogOpen}
                onClose={handleCloseDialog}
                aria-labelledby="form-dialog-title"
                PaperProps={{
                    style: {
                        width: '90vw',
                        height: '90vh',
                        maxWidth: 'none',
                    },
                }}
            >
                <DialogTitle>Agregar Producto</DialogTitle>
                <DialogContent>
                    <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
                        <Box sx={{ width: '35%', p: 2 }}>
                            <TableContainer component={Paper} elevation={4} style={{ maxHeight: 500 }}>
                                <Table stickyHeader aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>SKU</TableCell>
                                            <TableCell>Cantidad</TableCell>
                                            <TableCell>Comando</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {venta.detalles_venta.map((detalle) => (
                                            <TableRow
                                                key={detalle.producto.id}
                                                onClick={() => setSelectedRow(detalle.producto)}
                                                sx={{
                                                    backgroundColor: selectedRow?.id === detalle.producto.id ? 'rgba(173, 216, 230, 0.5)' : 'inherit',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                <TableCell>{detalle.producto.sku}</TableCell>
                                                <TableCell>{detalle.cantidad}</TableCell>
                                                <TableCell>
                                                    <Button
                                                        size="small"
                                                        color="error"
                                                        onClick={() => quitarProducto(detalle.producto.id, venta.id)}
                                                    >
                                                        <ClearIcon/>
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                        <Box sx={{ width: '65%', p: 2  }}>
                            <Paper elevation={4} sx={{ width: '100%', height: '100%', p: 2 }}>
                                {vista === 'seleccionarMarca' && (
                                    <>
                                        <DialogContentText>
                                            Selecciona una marca para continuar.
                                        </DialogContentText>
                                        <Select
                                            value={marcaSeleccionada}
                                            onChange={handleMarcaChange}
                                            fullWidth
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 300,
                                                    },
                                                },
                                            }}
                                        >
                                            {marcas.map((marca) => (
                                                <MenuItem key={marca.id} value={marca.id}>
                                                    {marca.descripcion}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </>
                                )}
                                {vista === 'mostrarTabla' && (
                                    loading ? <CircularProgress /> : <TablaProductos productos={productosDisponibles} setSelectedRow={setSelectedRow} selectedRow={selectedRow} />
                                )}
                                {vista === 'detalleProducto' && selectedRow && (
                                    <>
                                        <Typography variant="h6" gutterBottom>Verifique el Producto a Agregar</Typography>
                                        <Typography variant="subtitle1" gutterBottom>SKU: {selectedRow.sku}</Typography>
                                        <Typography variant="subtitle1" gutterBottom>Descripción: {selectedRow.descripcion}</Typography>
                                        <TextField
                                            label="Cantidad"
                                            type="number"
                                            value={cantidad}
                                            onChange={(e) => setCantidad(parseInt(e.target.value) || 0)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            sx={{ mt: 4 }}
                                            variant="outlined"
                                        />
                                    </>
                                )}
                            </Paper>

                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    {vista === 'seleccionarMarca' && (
                        <>
                            <Button onClick={handleCloseDialog} color="primary">
                                Cerrar
                            </Button>
                            <Button onClick={() => { setVista('mostrarTabla'); handleContinue(); }} color="primary" disabled={!marcaSeleccionada}>
                                Continuar
                            </Button>
                        </>
                    )}
                    {vista === 'mostrarTabla' && (
                        <>
                            <Button onClick={() => setVista('seleccionarMarca')} color="primary">
                                Atrás
                            </Button>
                            <Button onClick={() => setVista('detalleProducto')} color="primary" disabled={!selectedRow}>
                                Continuar
                            </Button>
                        </>
                    )}
                    {vista === 'detalleProducto' && (
                        <>
                            <Button onClick={() => setVista('mostrarTabla')} color="primary">
                                Atrás
                            </Button>
                            <Button onClick={AgregarProductoVenta} color="primary" disabled={!selectedRow || cantidad <= 0}>
                                Agregar
                            </Button>
                        </>
                    )}
                </DialogActions>
            </Dialog>

            <TableRow sx={{ backgroundColor: hasVirtualCombo ? 'rgba(255, 150, 136, 0.4)' : 'inherit' }}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ m: 2, p: 3, backgroundColor: "#fff", borderRadius: 2 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                Detalles de Productos
                            </Typography>
                            <Table size="small" aria-label="productos">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>SKU</TableCell>
                                        <TableCell>Cantidad</TableCell>
                                        <TableCell>Descripción</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {venta.detalles_venta.map((detalle) => (
                                        <TableRow key={detalle.producto.id} sx={{ backgroundColor: detalle.producto.es_combo_virtual ? 'rgba(255, 150, 136, 0.4)' : 'inherit' }}>
                                            <TableCell>{detalle.producto.sku}</TableCell>
                                            <TableCell>{detalle.cantidad}</TableCell>
                                            <TableCell>{detalle.producto.descripcion}</TableCell>
                                            <TableCell>
                                                <Tooltip title="Quitar Producto">
                                                    <IconButton color="error" onClick={() => quitarProducto(detalle.producto.id, venta.id)}>
                                                        <ClearIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default CarritosTable;