import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    Typography,
    IconButton,
    Collapse,
    Tooltip,
    ButtonGroup,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Select,
    MenuItem,
    CircularProgress, Snackbar, Alert,
    Button
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PrintIcon from '@mui/icons-material/Print';
import DoneIcon from '@mui/icons-material/Done';
import {
    agregarProductoVenta,
    cargarProductosPorMarca,
    obtener_marcas_productos,
    obtener_productos_de_empresa, quitarProductoVenta
} from "../../../Globales/MetodosAPIs"
import { useAuth } from "../../../Providers/AuthProvider";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import SellIcon from '@mui/icons-material/Sell';
import { useWs } from "../../../Providers/EmbalajeProvider";
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ObservationDialog from "../Dialogs/DialogoObservacionCarrito";
import RestoreIcon from '@mui/icons-material/Restore';
const CarritoEmbalajeTable = ({ carritos, setRefresh, filasSeleccionadas, setFilasSeleccionadas,pageNumber }) => {
    const { setEnviar } = useWs();

    const handleResetearUltimoCarritoCerrado = () => {
        setEnviar({ action: "cambiar_ultimo_carrito_cerrado_a_preparacion", values: {} });
        // Aquí puedes realizar la acción necesaria, como enviar los datos a través de WebSocket
    };

    return (
        <Paper elevation={3} sx={{ height: '100%', maxHeight: "900px", width: "100%",minHeight:"700px" }}>
            <Box sx={{ display: 'flex', alignItems: 'center', p: 3, justifyContent: 'space-between' }}>
                <Tooltip title="Deshacer Ultimo Carrito cerrado">
                    <Button variant="contained" onClick={handleResetearUltimoCarritoCerrado}>
                        <RestoreIcon />
                    </Button>
                </Tooltip>
            </Box>
            <TableContainer component={Paper} style={{ maxHeight: 600, overflow: 'auto' }}>
                <Table aria-label="tabla de carritos" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ borderBottom: "3px solid rgba(80,80,80,0.5)" }} />
                            <TableCell style={{ borderBottom: "3px solid rgba(80,80,80,0.5)" }}>Código Carrito</TableCell>
                            <TableCell style={{ borderBottom: "3px solid rgba(80,80,80,0.5)" }}>Estado</TableCell>
                            <TableCell style={{ borderBottom: "3px solid rgba(80,80,80,0.5)" }}>Tipo de Envío</TableCell>
                            <TableCell style={{ borderBottom: "3px solid rgba(80,80,80,0.5)" }}>Acciones</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {carritos.map((carrito) => (
                            <CarritoRow
                                key={carrito.id}
                                carrito={carrito}
                                setRefresh={setRefresh}
                                isSelected={filasSeleccionadas.indexOf(carrito.id) !== -1}
                                setFilasSeleccionadas={setFilasSeleccionadas}
                                filasSeleccionadas={filasSeleccionadas}
                                pageNumber={pageNumber}

                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};


const CarritoRow = ({ carrito, setRefresh, isSelected, pageNumber }) => {
    const { ultimoMensajeGeneral, setEnviar } = useWs();
    const [open, setOpen] = useState(carrito.estado === 'en_preparacion'); // Desplegar automáticamente si el estado es 'en_preparacion'

    const hasVirtualCombo = carrito.ventas.some(venta =>
        venta.detalles_venta.some(detalle => detalle.producto.es_combo_virtual)
    );
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleCantidadChange = async (ventaId, productoId, nuevaCantidad) => {
        try {
            setEnviar({ action: "actualizar_cantidad_preparada", values: { venta_id: ventaId, producto_id: productoId, cantidad: nuevaCantidad } })
            setRefresh((prev) => prev + 1); // Actualizar para refrescar la vista
        } catch (error) {
            console.error('Error al actualizar la cantidad preparada:', error);
        }
    };

    const productosConVenta = carrito.ventas.flatMap((venta) =>
        venta.detalles_venta.map((detalle) => ({
            ...detalle,
            ventaId: venta.id
        }))
    );



    const handleObservationSubmit = ({ motivo, descripcion }) => {
        setEnviar({ action: "observar_carrito", values: { carrito_id: carrito.id, motivo: motivo, observacion: descripcion,page_number:pageNumber } })
        // Aquí puedes realizar la acción necesaria, como enviar los datos a través de WebSocket
    };

    return (
        <>
            <ObservationDialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                onSubmit={handleObservationSubmit}
            />
            <TableRow
                aria-checked={isSelected}
                tabIndex={-1}
            >
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {carrito.id}
                </TableCell>
                <TableCell>{carrito.estado_display}</TableCell>
                <TableCell>{carrito.detalles_envio.tipo_de_envio_display}</TableCell>
                <TableCell>
                    <ButtonGroup variant="contained">

                        <Tooltip title="Observar Carrito">
                            <span>
                                <Button color="error" disabled={carrito.estado === 'asignado'} onClick={() => setDialogOpen(true)}>
                                    <ErrorOutlineIcon />
                                </Button>
                            </span>
                        </Tooltip>

                        {carrito.estado === 'asignado' && (
                            <Tooltip title="Iniciar Preparacion">
                                <span>
                                    <Button onClick={() => { setEnviar({ action: "iniciar_preparacion_carrito", values: { carrito_id: carrito.id } }) }}>
                                        <ShoppingCartCheckoutIcon />
                                    </Button>
                                </span>
                            </Tooltip>)
                        }

                        {carrito.estado === 'en_preparacion' && carrito.detalles_envio.tipo_de_envio !== 'acuerdo_con_comprador' && (
                            <Tooltip title="Imprimir Etiqueta">
                                <span>
                                    <Button
                                        color={carrito.restriccion_ean ? "primary" : "success"}
                                        onClick={() => { setEnviar({ action: "imprimir_etiqueta_del_carrito", values: { carrito_id: carrito.id } }) }}
                                    >
                                        <PrintIcon />
                                    </Button>
                                </span>
                            </Tooltip>)
                        }


                        {(carrito.detalles_envio.tipo_de_envio === 'acuerdo_con_comprador' || carrito.detalles_envio.tipo_de_envio === 'self_service') && (
                            <Tooltip title="Cerrar Carrito">
                                <span>
                                    <Button disabled={carrito.estado === 'asignado'} color="success" onClick={() => { setEnviar({ action: "cerrar_carrito_manualmente", values: { carrito_id: carrito.id } }) }}>
                                        <DoneIcon />
                                    </Button>
                                </span>
                            </Tooltip>
                        )}


                    </ButtonGroup>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1, borderRadius: 2 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                Detalles de Envío
                            </Typography>
                            <Typography variant="body1" mt={2} mb={2}>
                                Fecha Límite del Carrito: {carrito.detalles_envio.fecha_limite_retiro}
                            </Typography>

                            {carrito.estado === 'en_observacion' && carrito.detalle_observacion && (
                                <Box sx={{ marginBottom: 2, padding: 2, border: '1px solid #ff9800', borderRadius: 2, display: 'flex', justifyContent: 'space-between' }}>
                                    <Box>
                                        <Typography variant="h6" gutterBottom component="div">
                                            Detalles de la Observación
                                        </Typography>
                                        <Typography variant="body2">
                                            <strong>Motivo:</strong> {carrito.detalle_observacion.motivo_display}
                                        </Typography>
                                        <Typography variant="body2">
                                            <strong>Observación:</strong> {carrito.detalle_observacion.observacion}
                                        </Typography>
                                    </Box>
                                </Box>
                            )}



                            <Box sx={{ marginBottom: 2, padding: 2, border: '1px solid #ff9800', borderRadius: 2 }}>
                                <Typography variant="h6" gutterBottom component="div">
                                    Códigos de Venta Asociados
                                </Typography>
                                {carrito.ventas.map((venta) => (
                                    <Typography variant="body2" key={venta.id}>
                                        <strong>Venta ID:</strong> {venta.id}
                                    </Typography>
                                ))}
                            </Box>

                            <Box sx={{ m: 2, p: 3, backgroundColor: "#fff", borderRadius: 2 }}>
                                <Typography variant="h6" gutterBottom component="div">
                                    Productos a Preparar
                                </Typography>
                                <Table size="small" aria-label="productos">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>SKU</TableCell>
                                            <TableCell>Cantidad Del Pedido</TableCell>
                                            <TableCell>Cantidad Preparada</TableCell>
                                            <TableCell>Descripción</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {productosConVenta.map((detalle) => {
                                            const esEditable = detalle.producto.ean === null;
                                            const cantidadPreparada = detalle.cantidad_preparada ?? detalle.cantidad;

                                            return (
                                                <TableRow
                                                    key={detalle.producto.id}
                                                    sx={{
                                                        backgroundColor: detalle.producto.es_combo_virtual
                                                            ? 'rgba(255, 150, 136, 0.4)'
                                                            : 'inherit'
                                                    }}
                                                >
                                                    <TableCell>{detalle.producto.sku}</TableCell>
                                                    <TableCell>{detalle.cantidad}</TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            type="number"
                                                            variant="outlined"
                                                            size="small"
                                                            value={cantidadPreparada}
                                                            onChange={(e) => handleCantidadChange(detalle.ventaId, detalle.producto.id, parseInt(e.target.value) || 0)}
                                                            disabled={!esEditable}
                                                            InputProps={{
                                                                inputProps: {
                                                                    min: 0
                                                                }
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell>{detalle.producto.descripcion}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default CarritoEmbalajeTable;
