import React, { useEffect, useRef, useState } from 'react';
import Paper from '@mui/material/Paper';
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { useAuth } from "../../Providers/AuthProvider";
import swal from "sweetalert";
import { InputAdornment, InputLabel, Pagination, Select, MenuItem, FormControl, TextField } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import Swal from "sweetalert2";
import { useWs } from "../../Providers/EmbalajeProvider";
import CarritoEmbalajeTable from "./Tablas/CarritoEmbalajeTabla";

const Embalaje = () => {
  const { ultimoMensajeGeneral, setEnviar } = useWs();
  const { authTokens } = useAuth();
  const [carritosAsignados, setCarritosAsignados] = useState([]);
  const [selectedTipoEnvio, setSelectedTipoEnvio] = useState('all');
  const [selectedMarca, setSelectedMarca] = useState(0);
  const [numeroVentaOCarrito, setNumeroVentaOCarrito] = useState('');
  const [refresh, setRefresh] = useState(0);
  const [filasSeleccionadas, setFilasSeleccionadas] = useState([]);
  const [filteredCarritos, setFilteredCarritos] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [marcasProductos, setMarcasProductos] = useState([{ id: 0, descripcion: "Todas las Marcas" }]);
  const [totalPages, setTotalPages] = useState(1);
  const carritosRecibidosRef = useRef(false);
  const retryTimeoutRef = useRef(null);

  const pageSize = 15;

  const options = [
    { id: "all", desc: "Todos los Envios" },
    { id: "fulfillment", desc: "Envio Full" },
    { id: "self_service", desc: "Envio Flex" },
    { id: "cross_docking", desc: "Colecta" },
    { id: "acuerdo_con_comprador", desc: "Acuerdo con Comprador" }
  ];

  const solicitarMarcas = () => {
    setEnviar({ action: "obtener_marcas_de_cliente", values: {} });
  };

  const solicitarCarritosAsignados = (page = 1, tipoEnvio = selectedTipoEnvio, marca = selectedMarca, numeroVentaOCarrito = '') => {
    setEnviar({ action: "mis_carritos_asignados", values: { page_number: page, page_size: pageSize, tipo_envio: tipoEnvio, marca: marca, numero_venta_o_carrito: numeroVentaOCarrito } });
  };

  useEffect(() => {
    solicitarCarritosAsignados(pageNumber, selectedTipoEnvio, selectedMarca, numeroVentaOCarrito);
  }, [pageNumber, pageSize, selectedTipoEnvio, selectedMarca, numeroVentaOCarrito]);

  useEffect(() => {
    if (ultimoMensajeGeneral) {
      if (ultimoMensajeGeneral.action === 'carritos_asignados') {
        solicitarMarcas();
        setCarritosAsignados(ultimoMensajeGeneral.values);
        setTotalPages(ultimoMensajeGeneral.pagination.total_pages);
        carritosRecibidosRef.current = true;

        if (retryTimeoutRef.current) {
          clearTimeout(retryTimeoutRef.current);
        }

        retryTimeoutRef.current = setTimeout(() => {
          solicitarCarritosAsignados(pageNumber, selectedTipoEnvio, selectedMarca, numeroVentaOCarrito);
        }, 20000);
      } else if (ultimoMensajeGeneral.action === 'marcas_productos') {
        console.log("Se recibieron las marcas: ", ultimoMensajeGeneral.values);
        setMarcasProductos([{ id: 0, descripcion: "Todas las Marcas" }, ...ultimoMensajeGeneral.values]);
      } else {
        const swalOptions = {
          title: ultimoMensajeGeneral.status === 'error' ? 'Error' : 'Éxito',
          text: ultimoMensajeGeneral.message,
          icon: ultimoMensajeGeneral.status === 'error' ? 'error' : 'success',
          confirmButtonText: 'Ok'
        };
        Swal.fire(swalOptions);
      }
    }
  }, [ultimoMensajeGeneral]);

  useEffect(() => {
    setFilteredCarritos(carritosAsignados);
  }, [carritosAsignados]);

  const handlePageChange = (event, value) => {
    setPageNumber(value);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setNumeroVentaOCarrito(value);
    if (value.length >= 5) {
      solicitarCarritosAsignados(pageNumber, selectedTipoEnvio, selectedMarca, value);
    }
  };

  return (
    <Container maxWidth="lg" style={{ height: '100vh' }}>
      <Box sx={{ height: '100%', width: "100%", display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ height: '10%', p: 3, width: "100%" }}>
          <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: 'center' }}>
            <TextField
              sx={{ flexGrow: 1 }}
              placeholder="Buscar Venta"
              value={numeroVentaOCarrito}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: "center" }}>
              <FormControl variant="outlined" sx={{ m: 1, width: 240 }}>
                <InputLabel id="envio-select-label">Envío</InputLabel>
                <Select
                  labelId="envio-select-label"
                  id="envio-select"
                  value={selectedTipoEnvio}
                  onChange={e => setSelectedTipoEnvio(e.target.value)}
                  label="Envío"
                >
                  {options.map(option => (
                    <MenuItem key={option.id} value={option.id} sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                      {option.desc}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl variant="outlined" sx={{ m: 1, width: 240 }}>
                <InputLabel id="marca-select-label">Marca</InputLabel>
                <Select
                  labelId="marca-select-label"
                  id="marca-select"
                  value={selectedMarca}
                  onChange={e => setSelectedMarca(e.target.value)}
                  label="Marca"
                >
                  {marcasProductos.map(marca => (
                    <MenuItem key={marca.id} value={marca.id} sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                      {marca.descripcion}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              {/* Espacio reservado para futuros botones o acciones */}
            </Box>
          </Box>
        </Box>
        <Box sx={{ height: '90%', p: 3, width: "100%" }}>
          <Paper elevation={8} sx={{ height: '100%', maxHeight: "600px", width: "100%" }}>
            <CarritoEmbalajeTable
              carritos={filteredCarritos}
              setRefresh={setRefresh}
              filasSeleccionadas={filasSeleccionadas}
              setFilasSeleccionadas={setFilasSeleccionadas}
              pageNumber={pageNumber}
            />
            <Pagination count={totalPages} page={pageNumber} onChange={handlePageChange} />
          </Paper>
        </Box>
      </Box>
    </Container>
  );
};

export default Embalaje;
