import logo from './logo.svg';
import './App.css';
import {AuthProvider, useAuth} from "./Providers/AuthProvider";
import SignIn from "./Vistas/Login/login";

import Sidebar from "./Globales/Sidebar"
import Dashboard from "./Vistas/Dashboard/DashBoard";
import React from 'react';
import CarritosPedidos from "./Vistas/AdministrarVentas/CarritosPedidos";
import Embalaje from "./Vistas/Embalaje/Embalaje";
import WebsocketProvider, {useWs} from "./Providers/EmbalajeProvider";
import UserAdmin from "./Vistas/AdministrarUsuarios/UserAdmin"
import SshTerminal from "./Vistas/SSHManager/Manager";
import Configuracion from "./Vistas/Configuracion/Configuracion";
import {SeleccionEmpresa} from "./Vistas/SeleccionEmpresa/SeleccionEmpresa";
import BuscadorVenta from "./Vistas/AdministrarVentas/BuscardorVentas";
function AuthenticatedApp() {
  const { authTokens,actualView,setView,empresaSeleccionada } = useAuth();
  return (
      <>
      {empresaSeleccionada ? (
          <>

    <Sidebar/>
    <WebsocketProvider>
        {actualView === 'dashboard' && (<Dashboard/>)}
        {actualView === 'carrito_de_cuenta' && (<CarritosPedidos/>)}
        {actualView === 'buscar_venta' && (<BuscadorVenta/>)}
        {actualView === 'embalaje' && (<Embalaje/>)}
        {actualView === 'administrar_usuarios' && (<UserAdmin/>)}
        {actualView ==='configuracion' && (<Configuracion/>)}

      {/* Componentes protegidos post-login */}
    </WebsocketProvider>
    {actualView === 'ssh_terminal' && (<SshTerminal token={authTokens.accessToken} />)}

    </>
        ) : (<SeleccionEmpresa/>)}
      </>

  );
}


function App() {
    const { authTokens,actualView,setView } = useAuth();
  return (
    <div className="App">
          {actualView === 'login' ? <SignIn /> : <AuthenticatedApp />}
    </div>
  );
}

export default App;
