import React, { useState } from 'react';
import {
    Container,
    Paper,
    Typography,
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Snackbar, Alert
} from '@mui/material';
import QRCode from 'qrcode.react';
import { useAuth } from "../../Providers/AuthProvider";
import { solicitarOtp, validarOtp } from "../../Globales/MetodosAPIs"; // Asegúrate de tener la función validarOtp

const Configuracion = () => {
  const { setView, logout, permisos, superuser, userInformation, authTokens } = useAuth();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const otpActivo = userInformation.otp_activo;
  const [open, setOpen] = useState(false);
  const [otpUri, setOtpUri] = useState('');
  const [codigo, setCodigo] = useState('');

  const handleOTP = async () => {
    if (otpActivo) {
      // Se debería eliminar
    } else {
      const response = await solicitarOtp(authTokens.accessToken);
      if (response.status === 'success') {
        setOtpUri(response.otp_uri);
        setOpen(true);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    setOtpUri('');
    setCodigo('');
  };

  const handleValidateOtp = async () => {
    const response = await validarOtp(codigo, authTokens.accessToken);
    if (response.status === 'success') {
        // OTP validado exitosamente
        setSnackbarMessage('OTP configurado exitosamente');
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
        handleClose();
    } else {
        // Manejar el error
        setSnackbarMessage(response.message);
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
        console.error(response.message);
    }
};


  return (
    <Container maxWidth="md">
      <Paper
        elevation={3}
        sx={{
          p: 2,
          mt: 2,
          minHeight: { md: '150px' },
          width: '100%',
          boxSizing: 'border-box'
        }}
      >
        <Typography variant="h6" align="left">
          Configuración de Triple Factor de Autenticación (3FA)
        </Typography>
        <Box mt={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="body1">
            Estado de OTP: {otpActivo ? "Configurado" : "Sin Configurar"}
          </Typography>
          <Button variant="contained" color={otpActivo ? "error" : "primary"} onClick={handleOTP}>
            {otpActivo ? "Eliminar" : "Configurar"}
          </Button>
        </Box>
      </Paper>

      <Paper
        elevation={3}
        sx={{
          p: 2,
          mt: 2,
          minHeight: { md: '150px' },
          width: '100%',
          boxSizing: 'border-box'
        }}
      >
        <Typography variant="h6" align="left">
          Otra Configuración
        </Typography>
        <Box mt={2}>
          <Typography variant="body1">
            Aquí puede ajustar otras configuraciones de su cuenta.
          </Typography>
        </Box>
      </Paper>

      <Paper
        elevation={3}
        sx={{
          p: 2,
          mt: 2,
          minHeight: { md: '150px' },
          width: '100%',
          boxSizing: 'border-box'
        }}
      >
        <Typography variant="h6" align="left">
          Configuración Adicional
        </Typography>
        <Box mt={2}>
          <Typography variant="body1">
            Aquí puede ajustar configuraciones adicionales.
          </Typography>
        </Box>
      </Paper>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Escanee el QR con su aplicación de autenticación OTP</DialogTitle>
        <DialogContent>
          <Box display="flex" justifyContent="center" mb={2}>
            {otpUri && <QRCode value={otpUri} size={256} />}
          </Box>
          <Typography variant="body1">
            Una vez escaneado, ingrese el código que se genera para validar el 3FA
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            label="Código OTP"
            type="text"
            fullWidth
            value={codigo}
            onChange={(e) => setCodigo(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleValidateOtp} color="primary">
            Validar OTP
          </Button>
        </DialogActions>
      </Dialog>
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
            <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
                {snackbarMessage}
            </Alert>
        </Snackbar>
    </Container>
  );
};

export default Configuracion;
