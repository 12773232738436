import React, { useEffect, useState } from 'react';
import {
  Container,
  Paper,
  Typography,
  Grid,
  Box,
  useTheme,
  useMediaQuery,
  CircularProgress,
  Backdrop
} from '@mui/material';
import swal from 'sweetalert';
import GraficoTiemposPorUsuario from "./graficos/TiemposUsuario";
import GraficoDonaPorUsuario from "./graficos/DonutDetalle";
import { listar_cuentas_empresa, obtenerGeneralDiarioPorUsuario, obtenerTiemposPromedioPorUsuario } from "../../Globales/MetodosAPIs"
import { useAccount } from "../../Providers/CuentaProvider";
import { useAuth } from "../../Providers/AuthProvider";

function Dashboard() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { setView, authTokens } = useAuth();
  const { cuentaSeleccionada, setCS, setCD } = useAccount();

  // Datos simulados de las ventas
  const [accounts, setAccounts] = useState([]);  // Inicializa con un valor por defecto si es necesario
  const [loadingId, setLoadingId] = useState(null); // Estado para manejar el ID de la cuenta en carga
  const [tiemposPromedio, setTiemposPromedio] = useState({});
  const [generalDiario, setGeneralDiario] = useState({});
  const [dataBarChart, setDataBarChart] = useState({
    labels: [],
    datasets: [
      { label: 'Embalados', data: [], backgroundColor: 'rgb(33, 150, 243,0.8)' },  // MUI Blue 500
      { label: 'Observados', data: [], backgroundColor: 'rgb(244, 67, 54,0.8)' },  // MUI Red 500
      { label: 'Sin Preparar', data: [], backgroundColor: 'rgb(255, 193, 7,0.8)' }  // MUI Amber 500
    ]
  });

  const [dataDonut, setDataDonut] = useState({
    labels: [],
    datasets: [
      {
        label: 'Tiempos Promedio',
        data: [],
        backgroundColor: [
          'rgb(244, 67, 54,0.8)',  // MUI Red 500
          'rgb(255, 193, 7,0.8)',  // MUI Amber 500
          'rgb(139, 195, 74,0.8)', // MUI Light Green 500
          'rgb(33, 150, 243,0.8)'  // MUI Blue 500
        ],
        borderColor: [
          'rgb(244, 67, 54,0.5)',  // MUI Red 500
          'rgb(255, 193, 7,0.5)',  // MUI Amber 500
          'rgb(139, 195, 74,0.5)', // MUI Light Green 500
          'rgb(33, 150, 243,0.5)'  // MUI Blue 500
        ],
        borderWidth: 1
      }
    ]
  });


  const minAccounts = 3; // Asegura que siempre haya al menos 3 Papers
  useEffect(() => {
    const fetchData = async () => {
      try {
        const cuentas = await listar_cuentas_empresa(authTokens.accessToken);
        setAccounts(cuentas.data);
      } catch (error) {
        console.error('Error al obtener las cuentas:', error);
      }
    };

    const fetchEstadisticas = async () => {
      try {
        const tiempos = await obtenerTiemposPromedioPorUsuario(authTokens.accessToken);
        setTiemposPromedio(tiempos.data);

        const general = await obtenerGeneralDiarioPorUsuario(authTokens.accessToken);
        setGeneralDiario(general.data);

        // Transformar datos para el bar chart
        const labels = Object.keys(general.data);
        const embalados = labels.map(user => general.data[user].embalados);
        const observados = labels.map(user => general.data[user].observados);
        const sinPreparar = labels.map(user => general.data[user].sin_preparar);

        setDataBarChart({
          labels,
          datasets: [
            { label: 'Embalados', data: embalados, backgroundColor: 'rgb(33, 150, 243,0.8)' },
            { label: 'Observados', data: observados, backgroundColor: 'rgb(244, 67, 54,0.8)' },
            { label: 'Sin Preparar', data: sinPreparar, backgroundColor: 'rgb(255, 193, 7,0.8)' }
          ]
        });

        // Transformar datos para el donut chart
        const tiempoLabels = Object.keys(tiempos.data);
        const tiempoData = tiempoLabels.map(user => {
          const [minutes, seconds] = tiempos.data[user].split(':').map(Number);
          return minutes * 60 + seconds; // Convertir a segundos
        });

        setDataDonut({
          labels: tiempoLabels,
          datasets: [
            {
              label: 'Tiempos Promedio',
              data: tiempoData,
              backgroundColor: [
                'rgb(244, 67, 54,0.8)',  // MUI Red 500
                'rgb(255, 193, 7,0.8)',  // MUI Amber 500
                'rgb(139, 195, 74,0.8)', // MUI Light Green 500
                'rgb(33, 150, 243,0.8)'  // MUI Blue 500
              ],
              borderColor: [
                'rgb(244, 67, 54,0.5)',  // MUI Red 500
                'rgb(255, 193, 7,0.5)',  // MUI Amber 500
                'rgb(139, 195, 74,0.5)', // MUI Light Green 500
                'rgb(33, 150, 243,0.5)'  // MUI Blue 500
              ],
              borderWidth: 1
            }
          ]
        });

      } catch (error) {
        console.error('Error al obtener estadísticas:', error);
      }
    };

    // Llamar a la función async
    fetchData();
    fetchEstadisticas();
  }, [authTokens.accessToken]);

  const manejarSeleccionCuenta = async (acc) => {
    setLoadingId(acc.id);
    try {
      await setCS(acc);
      setView('carrito_de_cuenta');
    } catch (error) {
      swal("Error", "Falló la operación.", "error");
    } finally {
      setLoadingId(null);
    }
  };

  const calculateSpan = () => {
    return Math.max(Math.floor(12 / accounts.length), 4); // Asegura un mínimo de '4' para evitar que sea demasiado pequeño
  };

  return (
    <Container maxWidth="lg">
      {/* Box component to divide the height into two parts */}
      <Box sx={{ height: '50vh', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Grid container spacing={2} justifyContent="center">
          {accounts.length > 0 && accounts.map((account) => (
            <Grid item xs={12} sm={6} md={calculateSpan()} key={account.id}>
              <Paper elevation={9} sx={{
                py: isSmallScreen ? 2 : 4,
                px: 2,
                backgroundColor: 'primary.main',
                color: 'white',
                height: '100%',
                display: 'flex',
                position: 'relative',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: "flex-start",
                borderRadius: "10px",
                '&:hover': {
                  backgroundColor: theme.palette.primary.dark,
                },
              }}
                onClick={() => { manejarSeleccionCuenta(account); }}>
                {loadingId === account.id && (
                  <Backdrop open={true} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, borderRadius: "10px" }}>
                    <CircularProgress color="inherit" />
                  </Backdrop>
                )}
                <Typography
                  variant="h6"
                  align="left"
                  sx={{
                    fontWeight: 'bold',
                    fontSize: isSmallScreen ? '1.25rem' : '1.75rem',
                    flex: 1, // Assigns flex grow to this Typography to take half the space
                  }}
                >
                  {account.usuario}
                </Typography>

                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
                  <Typography variant="body1" sx={{ fontSize: isSmallScreen ? '1rem' : '1.25rem' }} align="left">
                    Para Facturar: {account.ventas_a_facturar}
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: isSmallScreen ? '1rem' : '1.25rem' }} align="left">
                    Para Asignar: {account.ventas_a_asignar}
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: isSmallScreen ? '1rem' : '1.25rem' }} align="left">
                    Asignadas: {account.ventas_asignadas}
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: isSmallScreen ? '1rem' : '1.25rem' }} align="left">
                    Observadas: {account.ventas_observadas}
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: isSmallScreen ? '1rem' : '1.25rem' }} align="left">
                    Preparadas: {account.ventas_preparadas}
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: isSmallScreen ? '1rem' : '1.25rem' }} align="left">
                    Finalizadas: {account.ventas_cerradas}
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
      {/* Placeholder for the second half of the screen */}
      <Box sx={{ height: '50vh' }}>
        <Grid container spacing={2} sx={{ height: "100%", p: 2 }}>
          <Grid item xs={7} sx={{ height: "100%", p: 2, border: "5px solid rgba(128,128,128,0.5)", borderRadius: "10px" }}>
            <Typography>Ventas Segregadas por Usuario</Typography>
            <GraficoTiemposPorUsuario data={dataBarChart} />
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={4} sx={{ height: "100%", p: 2, border: "5px solid rgba(128,128,128,0.5)", borderRadius: "10px" }}>
            <Box pb={3}>
              <GraficoDonaPorUsuario data={dataDonut} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default Dashboard;
