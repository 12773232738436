import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { useAccount } from "../../Providers/CuentaProvider";
import TextField from "@mui/material/TextField";
import { InputAdornment, MenuItem, Select, FormControl, InputLabel, FormControlLabel } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { useAuth } from "../../Providers/AuthProvider";
import { CarritosPaginationTable } from "./Tablas/CarritoCompuesto";
import {
    obtener_carritos_de_cuenta_con_paginacion,
    listar_cuentas_empresa
} from "../../Globales/MetodosAPIs";
import swal from "sweetalert";
import Tooltip from '@mui/material/Tooltip';
import SellIcon from '@mui/icons-material/Sell';
import Checkbox from "@mui/material/Checkbox";

const BuscadorVenta = () => {
  const { authTokens } = useAuth();
  const [ventaFiltroDesc, setVFD] = useState(null);
  const [refresh, setRefresh] = useState(0);
  const [filasSeleccionadas, setFilasSeleccionadas] = useState([]);

  const [excluirARevisar, setExcluirARevisar] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState('');
  const [carritoData, setCarritoData] = useState([]); // Almacenar los datos de carritos
  const [currentPage, setCurrentPage] = useState(1); // Almacenar la página actual
  const [totalPages, setTotalPages] = useState(1); // Almacenar el número total de páginas
  const pageSize = 25; // Definido según la API

  useEffect(() => {
    const fetchAccounts = async () => {
      try {
        const cuentas = await listar_cuentas_empresa(authTokens.accessToken);
        setAccounts(cuentas.data);
        if (cuentas.data.length > 0) {
          const primeraCuenta = cuentas.data[0];
          setSelectedAccount(primeraCuenta.id);
          manejarSeleccionCuenta(primeraCuenta.id, 1); // Cargar la primera página
        }
      } catch (error) {
        console.error('Error al obtener las cuentas:', error);
      }
    };
    fetchAccounts();
  }, [authTokens.accessToken]);

  useEffect(() => {
    if (selectedAccount) {
      manejarSeleccionCuenta(selectedAccount, currentPage);
    }
  }, [currentPage, selectedAccount]);

  const handleBuscarVentaChange = async (e) => {
    const value = e.target.value;
    setVFD(value);

    if (value.length > 5) {
      try {
        const resultado = await obtener_carritos_de_cuenta_con_paginacion(selectedAccount, authTokens.accessToken, value, currentPage);
        if (resultado.status) {
          setCarritoData(resultado.data.results); // Almacenar los datos de carritos obtenidos
          setCurrentPage(resultado.data.current_page); // Almacenar la página actual
          setTotalPages(Math.ceil(resultado.data.count / pageSize)); // Calcular el número total de páginas
        } else {
          swal("Error", resultado.mensaje, "error");
        }
      } catch (error) {
        swal("Error", "Error al buscar la venta.", "error");
      }
    } else if (value.length === 0) {
      // Si el campo de búsqueda está vacío, cargar todos los carritos de la cuenta seleccionada
      manejarSeleccionCuenta(selectedAccount, 1); // Cargar la primera página
    }
  };

  const handleAccountChange = (event) => {
    setSelectedAccount(event.target.value);
    const selectedCuenta = accounts.find(account => account.id === event.target.value);
    manejarSeleccionCuenta(selectedCuenta.id, 1); // Cargar la primera página
  };

  const manejarSeleccionCuenta = async (id_cuenta, pagina = 1) => {
    try {
      const resultado = await obtener_carritos_de_cuenta_con_paginacion(id_cuenta, authTokens.accessToken, ventaFiltroDesc, pagina);
      if (resultado.status) {
        setCarritoData(resultado.data.results); // Almacenar los datos de carritos obtenidos
        setCurrentPage(pagina); // Establecer la página actual
        setTotalPages(Math.ceil(resultado.data.count / pageSize)); // Calcular el número total de páginas
      } else {
        swal("Error", resultado.mensaje, "error");
      }
    } catch (error) {
      swal("Error", "Falló la operación.", "error");
    }
  };

  const handleCheckboxChange = (event) => {
    setExcluirARevisar(event.target.checked);
    setFilasSeleccionadas([]);  // Limpiar filas seleccionadas
  };

  const contarVentasSeleccionadas = () => {
    return filasSeleccionadas.reduce((acc, carritoId) => {
      const carrito = carritoData.find(c => c.id === carritoId);
      return acc + (carrito ? carrito.ventas.length : 0);
    }, 0);
  };

  return (
    <Container maxWidth="lg" style={{ height: '100vh' }}>
      <Box sx={{ height: '100%', width: "100%", display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ height: '10%', p: 3, width: "100%" }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "center" }}>
            <TextField
              sx={{ flexGrow: 1 }}
              placeholder="Buscar Venta"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              value={ventaFiltroDesc}
              onChange={handleBuscarVentaChange}
            />
            <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <FormControl sx={{ minWidth: 120, marginRight: 2 }}>
                <InputLabel id="cuenta-label">Cuenta</InputLabel>
                <Select
                  labelId="cuenta-label"
                  id="cuenta-select"
                  value={selectedAccount}
                  label="Cuenta"
                  onChange={handleAccountChange}
                >
                  {accounts.map(account => (
                    <MenuItem key={account.id} value={account.id}>
                      {account.usuario}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Box>
        <Box sx={{ height: '90%', p: 3, width: "100%" }}>
          <Box sx={{ height: '90%', p: 3, width: "100%" }}>
            <Paper elevation={8} sx={{ height: '100%', maxHeight: "600px", width: "100%" }}>
              <Box sx={{ display: 'flex', alignItems: 'center', p: 3 }}>
                <Tooltip title="Ventas Seleccionadas">
                  <Paper
                    sx={{
                      minWidth: 100,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontWeight: 'bold',
                      bgcolor: 'primary.main',
                      color: 'white',
                      p: 1
                    }}
                  >
                    <SellIcon sx={{ marginRight: 1, color: 'white' }} /> {contarVentasSeleccionadas()}
                  </Paper>
                </Tooltip>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={excluirARevisar}
                      onChange={handleCheckboxChange}
                      color="primary"
                    />
                  }
                  label="Excluir 'A REVISAR'"
                  sx={{ marginLeft: 2 }}
                />
              </Box>
              <CarritosPaginationTable
                carritos={carritoData}
                setRefresh={setRefresh}
                filasSeleccionadas={filasSeleccionadas}
                setFilasSeleccionadas={setFilasSeleccionadas}
                currentPage={currentPage}
                totalPages={totalPages}
                setCurrentPage={setCurrentPage}
              />
            </Paper>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default BuscadorVenta;
