import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  IconButton,
  Box,
  CircularProgress,
  Select,
  MenuItem,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import { listarEmpresasDisponibles, agregarServidorCups, eliminarServidorCups } from "../../../Globales/MetodosAPIs";
import Typography from "@mui/material/Typography";
import Swal from 'sweetalert2';

const TablaServidoresCups = ({ data, accessToken, refresh }) => {
  const [newServidor, setNewServidor] = useState({
    username: '',
    descripcion: '',
    empresa: ''
  });
  const [open, setOpen] = useState(false);
  const [empresas, setEmpresas] = useState([]);
  const [loading, setLoading] = useState(true);
    const fetchEmpresas = async () => {
      setLoading(true);
      try {
        const response = await listarEmpresasDisponibles(accessToken);
        if (response.status) {
          setEmpresas(response.data);
        } else {
          console.error(response.mensaje);
        }
      } catch (error) {
        console.error(error.message);
      } finally {
        setLoading(false);
      }
    };

  useEffect(() => {
    fetchEmpresas();
  }, []);
  useEffect(() => {

    fetchEmpresas();
  }, [open]);

  const handleAdd = async () => {
    const response = await agregarServidorCups(accessToken, newServidor);
    if (response.status) {
      console.log(response.mensaje);
      setOpen(false);
      setNewServidor({ username: '', descripcion: '', empresa: '' });
      refresh();  // Refrescar la tabla después de agregar un servidor
    } else {
      console.error(response.mensaje);
    }
  };

  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: '¿Estás seguro?',
      text: "Esta acción no se puede deshacer",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar'
    });

    if (result.isConfirmed) {
      const response = await eliminarServidorCups(accessToken, id);
      if (response.status) {
        console.log(response.mensaje);
        refresh();  // Refrescar la tabla después de eliminar un servidor
      } else {
        console.error(response.mensaje);
      }
    }
  };

  const handleNewServidorChange = (e) => {
    const { name, value } = e.target;
    setNewServidor({ ...newServidor, [name]: value });
  };

  return (
    <TableContainer component={Paper}>
      <Box display="flex" justifyContent="flex-end" m={2}>
        <Tooltip title="Agregar Servidor CUPS">
          <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => setOpen(true)}>
            Agregar Servidor CUPS
          </Button>
        </Tooltip>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Descripción</TableCell>
            <TableCell>Empresa</TableCell>
            <TableCell>Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={3} align="center">
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : (
            data.map((servidor) => (
              <TableRow key={servidor.id}>
                <TableCell>{servidor.descripcion}</TableCell>
                <TableCell>{servidor.empresa || 'N/A'}</TableCell>
                <TableCell>
                  <Tooltip title="Eliminar Servidor">
                    <IconButton onClick={() => handleDelete(servidor.id)} color="secondary">
                      <ClearIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Agregar Servidor CUPS</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Complete los siguientes campos para agregar un nuevo servidor.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Usuario"
            type="text"
            fullWidth
            name="username"
            value={newServidor.username}
            onChange={handleNewServidorChange}
          />
          <TextField
            margin="dense"
            label="Descripción"
            type="text"
            fullWidth
            name="descripcion"
            value={newServidor.descripcion}
            onChange={handleNewServidorChange}
          />
          <Typography variant="subtitle1" gutterBottom>
            Empresa
          </Typography>
          <Select
            fullWidth
            name="empresa"
            value={newServidor.cuit}
            onChange={handleNewServidorChange}
          >
            {empresas.map((empresa) => (
              <MenuItem key={empresa.cuit} value={empresa.cuit}>
                <Typography>{empresa.razon_social}</Typography>
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleAdd} color="primary">
            Agregar
          </Button>
        </DialogActions>
      </Dialog>
    </TableContainer>
  );
};

export default TablaServidoresCups;
