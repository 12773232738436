import React, { createContext, useContext, useEffect, useRef, useState, useCallback } from "react";
import { WS_EMBALAJE } from "../Globales/MetodosAPIs";
import { v4 as uuidv4 } from 'uuid';
import { useAuth } from "./AuthProvider";

const GeneralContext = createContext();

// Hook para usar el contexto
export function useWs() {
    return useContext(GeneralContext);
}

// Proveedor del contexto
const WebsocketProvider = ({ children }) => {
    const { authTokens,empresaSeleccionada } = useAuth();
    const [mensajeAcompartirConWsGeneral, setEnviar] = useState({ 'action': null, 'value': null });
    const [ultimoMensajeGeneral, setUltimoMensajeGeneral] = useState(null);
    const [wsGeneralUrl, setWsGeneralUrl] = useState(null);
    const [wsGeneralCerrado, setWsGeneralCerrado] = useState(true);
    const [ultimosMensajesSalientesGeneral, setUltimosMensajesSalientesGeneral] = useState([]);
    const messageAttempts = useRef({});
    const authTokenRef = useRef(authTokens);
    const ws = useRef(null);
    const mensajePendiente = useRef(null);

    useEffect(() => {
        authTokenRef.current = authTokens;
        if (authTokens) {
            setWsGeneralUrl(WS_EMBALAJE.replace("<token>", authTokens.accessToken).replace("<empresa>", empresaSeleccionada));
        } else {
            setWsGeneralUrl(null);
            setWsGeneralCerrado(true);
        }
    }, [authTokens]);

    const send = useCallback((data, isResend = false) => {
        const message = {
            message: {
                ...data,
                uuid: isResend ? data.uuid : uuidv4(),
            }
        };

        if (ws.current && ws.current.readyState === WebSocket.OPEN) {
            ws.current.send(JSON.stringify(message));
            if (!isResend) {
                setUltimosMensajesSalientesGeneral(prevQueue => prevQueue.filter(item => item !== data));
            }

            if (!messageAttempts.current[message.uuid]) {
                messageAttempts.current[message.uuid] = 1;
            } else {
                messageAttempts.current[message.uuid]++;
            }

            // Reiniciar mensaje pendiente si se envía con éxito
            if (mensajePendiente.current === data) {
                mensajePendiente.current = null;
            }
        } else {
            mensajePendiente.current = data;
        }
    }, [setUltimosMensajesSalientesGeneral]);

    const manejarMensajeRecibido = useCallback((event) => {
        const data = JSON.parse(event.data);


        if (data.action === 'disconnect') {
            ws.current.close();
            return;
        }

        if (data.action === "ok") {
            delete messageAttempts.current[data.uuid];
        } else {

            setUltimoMensajeGeneral(data);
        }
    }, [send, setUltimoMensajeGeneral]);


    const manejarCierreOError = useCallback(() => {
        if (!authTokenRef.current) {
            setWsGeneralCerrado(true);
            return;
        }

        if (ws.current && ws.current.readyState !== WebSocket.OPEN) {
            setTimeout(() => {
                if (authTokenRef.current && ws.current.readyState !== WebSocket.CONNECTING && authTokens.accessToken) {
                    ws.current = new WebSocket(wsGeneralUrl);
                    ws.current.onopen = () => {
                        console.log("Conexión WebSocket iniciada correctamente.");
                        setWsGeneralCerrado(false);
                    };
                    ws.current.onmessage = manejarMensajeRecibido;
                    ws.current.onclose = manejarCierreOError;
                    ws.current.onerror = manejarCierreOError;
                }
            }, 2000);
        } else {
            setWsGeneralCerrado(true);
        }
    }, [wsGeneralUrl, manejarMensajeRecibido]);

    useEffect(() => {
        if (!wsGeneralUrl) return;

        if (!ws.current || ws.current.readyState > WebSocket.OPEN && wsGeneralCerrado) {
            ws.current = new WebSocket(wsGeneralUrl);
            ws.current.onopen = () => {
                console.log("Conexión WebSocket iniciada correctamente.");
                setWsGeneralCerrado(false);
            };
            ws.current.onmessage = manejarMensajeRecibido;
            ws.current.onclose = manejarCierreOError;
            ws.current.onerror = manejarCierreOError;
        }
    }, [wsGeneralUrl, wsGeneralCerrado, manejarMensajeRecibido, manejarCierreOError]);

    useEffect(() => {
        if (ws.current && ws.current.readyState === WebSocket.OPEN && ultimosMensajesSalientesGeneral.length > 0) {
            send(ultimosMensajesSalientesGeneral[0]);
        }
    }, [ultimosMensajesSalientesGeneral, send]);

    useEffect(() => {
        const keepAliveMessage = {
            action: 'keepalive',
            values: {}
        };

        const pingInterval = setInterval(() => {
            if (ws.current && ws.current.readyState === WebSocket.OPEN) {
                send(keepAliveMessage);
            }
        }, 2000);

        return () => clearInterval(pingInterval);
    }, [send]);

    useEffect(() => {
        if (mensajeAcompartirConWsGeneral.action) {
            mensajePendiente.current = mensajeAcompartirConWsGeneral;
            send(mensajeAcompartirConWsGeneral);
        }
    }, [mensajeAcompartirConWsGeneral, send]);

    // Reintentar enviar mensajes pendientes cada 1 segundo
    useEffect(() => {
        const retryInterval = setInterval(() => {
            if (mensajePendiente.current) {
                send(mensajePendiente.current);
            }
        }, 1000);

        return () => clearInterval(retryInterval);
    }, [send]);

    useEffect(() => {
        manejarCierreOError(); // Siempre intenta conectar al montar
    }, [manejarCierreOError]);

    return (
        <GeneralContext.Provider value={{
            mensajeAcompartirConWsGeneral,
            setEnviar,
            wsGeneralUrl,
            setWsGeneralUrl,
            ultimoMensajeGeneral,
            setUltimoMensajeGeneral,
            ultimosMensajesSalientesGeneral
        }}>
            {children}
        </GeneralContext.Provider>
    );
}

export default WebsocketProvider;
