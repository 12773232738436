import React from 'react';
import {
  Paper,
  Typography,
  Button,
  Grid,
  Box
} from '@mui/material';
import {useAuth} from "../../Providers/AuthProvider";



export const SeleccionEmpresa = () => {

    const {seleccionar_empresa ,empresasDisponibles} = useAuth();

    const handleSeleccionEmpresa = (id) => {
        seleccionar_empresa(id);
        console.log(`Seleccionada empresa con ID: ${id}`);
    };

  return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
            padding={2}
        >
            <Paper
                elevation={5}
                sx={{
                    borderRadius: 4,
                    padding: { xs: 2, sm: 3, md: 4 },
                    width: { xs: '100%', sm: '80%', md: '60%', lg: '40%' },
                    maxWidth: '500px',
                    maxHeight: '80vh', // Limita la altura máxima del Paper
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <Typography
                    variant="h5"
                    component="h1"
                    align="center"
                    gutterBottom
                    sx={{ mb: 3 }}
                >
                    Seleccione Empresa a Trabajar
                </Typography>
                <Box
                    sx={{
                        overflowY: 'auto',
                        maxHeight: 'calc(350px - 24px - 3rem)', // 350px menos el padding y el espacio del título
                        mt: 2
                    }}
                >
                    <Grid container spacing={2}>
                        {empresasDisponibles.map((empresa) => (
                            <Grid item xs={12} key={empresa.id}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    size="large"
                                    onClick={() => handleSeleccionEmpresa(empresa.id)}
                                >
                                    {empresa.descripcion}
                                </Button>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Paper>
        </Box>
    );
};